:root {
  // Blues
  --ts-blue-10: #f2f7ff;
  --ts-blue-20: #dee8fa;
  --ts-blue-30: #cedcf5;
  --ts-blue-40: #abc7f9;
  --ts-blue-50: #71a1f4;
  --ts-blue-60: #2770ef;
  --ts-blue-70: #2359b6;
  --ts-blue-80: #163772;
  --ts-blue-90: #082559;
  --ts-blue-100: #001740;

  // Greens
  --ts-green-10: #edfff9;
  --ts-green-20: #e0f8ef;
  --ts-green-30: #c7f2e3;
  --ts-green-40: #9be5cb;
  --ts-green-50: #56d3a8;
  --ts-green-60: #06bf7f;
  --ts-green-70: #049160;
  --ts-green-80: #025b3c;
  --ts-green-90: #003b26;
  --ts-green-100: #001f14;

  // Reds
  --ts-red-10: #fff0f0;
  --ts-red-20: #ffebec;
  --ts-red-30: #fcd4d7;
  --ts-red-40: #f9b3b9;
  --ts-red-50: #f47e89;
  --ts-red-60: #e22b3d;
  --ts-red-70: #b6313e;
  --ts-red-80: #721f27;
  --ts-red-90: #3d090e;
  --ts-red-100: #1f0003;

  // Purples
  --ts-purple-10: #f7f5ff;
  --ts-purple-20: #f0ebff;
  --ts-purple-30: #e3d9fc;
  --ts-purple-40: #d1c0fb;
  --ts-purple-50: #b094f8;
  --ts-purple-60: #8c62f5;
  --ts-purple-70: #6a4aba;
  --ts-purple-80: #422e75;
  --ts-purple-90: #210f4f;
  --ts-purple-100: #0e0033;

  // Yellows
  --ts-yellow-10: #fffbf0;
  --ts-yellow-20: #fff8e5;
  --ts-yellow-30: #fcf1d1;
  --ts-yellow-40: #fde9af;
  --ts-yellow-50: #fcd977;
  --ts-yellow-60: #fcc838;
  --ts-yellow-70: #bf982a;
  --ts-yellow-80: #785f1a;
  --ts-yellow-90: #4f3d09;
  --ts-yellow-100: #2e2200;

  // Teals
  --ts-teal-10: #edfdff;
  --ts-teal-20: #e1f7fa;
  --ts-teal-30: #c9f0f5;
  --ts-teal-40: #b5ecf2;
  --ts-teal-50: #82dfe9;
  --ts-teal-60: #48d1e0;
  --ts-teal-70: #369faa;
  --ts-teal-80: #22636b;
  --ts-teal-90: #0c3f45;
  --ts-teal-100: #002d33;

  // Oranges
  --ts-orange-10: #fff5f0;
  --ts-orange-20: #ffeee5;
  --ts-orange-30: #fdc;
  --ts-orange-40: #ffccb3;
  --ts-orange-50: #ffa97e;
  --ts-orange-60: #ff8142;
  --ts-orange-70: #c26232;
  --ts-orange-80: #7a3d1f;
  --ts-orange-90: #471f0b;
  --ts-orange-100: #310;

  // Grays
  --ts-gray-05: #fbfbfd;
  --ts-gray-10: #f6f8fa;
  --ts-gray-20: #eaedf2;
  --ts-gray-30: #dbdfe7;
  --ts-gray-40: #c0c6cf;
  --ts-gray-50: #a5acb9;
  --ts-gray-60: #777e8b;
  --ts-gray-70: #4a515e;
  --ts-gray-80: #323946;
  --ts-gray-90: #1d232f;
  --ts-gray-100: #000;

  --ts-gray-10-rgb: 246, 248, 250;
  --ts-gray-20-rgb: 234, 237, 242;
  --ts-gray-30-rgb: 219, 223, 231;
  --ts-gray-40-rgb: 192, 198, 207;
  --ts-gray-50-rgb: 165, 172, 185;
  --ts-gray-60-rgb: 119, 126, 139;
  --ts-gray-70-rgb: 74, 81, 94;
  --ts-gray-90-rgb: 29, 35, 47;
  --ts-gray-100-rgb: 0, 0, 0;
}
