:root {
  // Colors
  --ds-black: #000;
  --ds-black-rgb: 0, 0, 0;
  --ds-white: #fff;
  --ds-white-rgb: 255, 255, 255;
  --ds-gray-100: #fcfcfc;
  --ds-gray-200: #f8f9f7;
  --ds-gray-300: #f3f5f3;
  --ds-gray-400: #eaede8;
  --ds-gray-500: #dfe2de;
  --ds-gray-600: #ced1cd;
  --ds-gray-700: #b6bab4;
  --ds-gray-800: #7f827e;
  --ds-gray-900: #6c6e6b;
  --ds-gray-1000: #595c58;
  --ds-gray-1100: #3d3d3b;
  --ds-gray-1200: #242524;
  --ds-gray-300-rgb: 243, 245, 243;
  --ds-gray-500-rgb: 223, 226, 222;
  --ds-gray-600-rgb: 206, 209, 205;
  --ds-gray-700-rgb: 182, 186, 180;
  --ds-gray-900-rgb: 108, 110, 107;
  --ds-gray-1000-rgb: 89, 92, 88;
  --ds-gray-1100-rgb: 61, 61, 59;
  --ds-gray-1200-rgb: 36, 37, 36;
  --ds-green-100: #eef2e3;
  --ds-green-200: #c8f169;
  --ds-green-300: #c8f169;
  --ds-green-400: #78c51c;
  --ds-green-500: #78c51c;
  --ds-green-600: #2a6f2b;
  --ds-green-700: #2a6f2b;
  --ds-green-800: #043f2e;
  --ds-green-900: #043f2e;
  --ds-green-500-rgb: 120, 197, 28;
  --ds-blue-100: #dde7fa;
  --ds-blue-200: #bbd1fb;
  --ds-blue-300: #7ea6f3;
  --ds-blue-400: #7ea6f3;
  --ds-blue-500: #3d70d4;
  --ds-blue-600: #3d70d4;
  --ds-blue-700: #2c5bb6;
  --ds-blue-800: #2c5bb6;
  --ds-blue-900: #2c5bb6;
  --ds-blue-600-rgb: 61, 112, 212;
  --ds-purple-100: #e6e4fc;
  --ds-purple-200: #ccc8ff;
  --ds-purple-300: #a68fff;
  --ds-purple-400: #7456ce;
  --ds-purple-500: #5f41b7;
  --ds-purple-600: #5f41b7;
  --ds-purple-700: #5f41b7;
  --ds-purple-800: #242524;
  --ds-purple-900: #242524;
  --ds-purple-enhanced-200: #a68fff;
  --ds-purple-enhanced-300: #7456ce;
  --ds-red-100: #cd1515;
  --ds-red-200: #cd1515;
  --ds-red-300: #cd1515;
  --ds-red-400: #cd1515;
  --ds-red-500: #cd1515;
  --ds-red-600: #cd1515;
  --ds-red-700: #cd1515;
  --ds-red-800: #cd1515;
  --ds-red-900: #cd1515;
  --ds-pink-100: #f8dfe3;
  --ds-pink-200: #ffbbc6;
  --ds-pink-300: #fb8492;
  --ds-pink-400: #fb8492;
  --ds-pink-500: #cc416b;
  --ds-pink-600: #cc416b;
  --ds-pink-700: #b32447;
  --ds-pink-800: #b32447;
  --ds-pink-900: #242524;
  --ds-orange-100: #faefd9;
  --ds-orange-200: #ffd087;
  --ds-orange-300: #ffc066;
  --ds-orange-400: #eda94e;
  --ds-orange-500: #d99036;
  --ds-orange-600: #c78227;
  --ds-orange-700: #b37112;
  --ds-orange-800: #a26101;
  --ds-orange-900: #61440a;
  --ds-yellow-100: #fffede;
  --ds-yellow-200: #fff7bc;
  --ds-yellow-300: #ffec94;
  --ds-yellow-400: #e7c03a;
  --ds-yellow-500: #e7c03a;
  --ds-yellow-600: #ddb547;
  --ds-yellow-700: #c59b2b;
  --ds-yellow-800: #b38714;
  --ds-yellow-900: #74621e;

  // Status colors
  --ds-status-error-dark: #cd1515;
  --ds-status-error-mid: #ff6a6a;
  --ds-status-error-light: #ffe9e9;
  --ds-status-success-dark: #26992b;
  --ds-status-success-mid: #47c94d;
  --ds-status-success-light: #e6f7df;
  --ds-status-warning-dark: #c8a336;
  --ds-status-warning-mid: #e7c03a;
  --ds-status-warning-light: #f7f1da;

  // Attr colors
  --ds-attr-measure-icon-color: var(--ds-green-400);
  --ds-attr-dimension-icon-color: var(--ds-blue-400);

  // Field colors
  --ds-product-continuous-pill: #e6f3c0;
  --ds-product-continuous-icon-color: var(--ds-green-500);
  --ds-product-continuous-pill-dark: var(--ds-green-500);
  --ds-product-discrete-pill: var(--ds-blue-100);
  --ds-product-discrete-icon-color: var(--ds-blue-500);
  --ds-product-discrete-pill-dark: var(--ds-blue-500);

  // Tooltips
  --ds-product-tooltip-background-color: var(--ds-gray-1100);

  // SQL editor
  --ds-product-syntax-background-dark: #161816;
  --ds-product-syntax-background-med: #272927;
  --ds-product-syntax-ai-dark: #a0c6ff;
  --ds-product-syntax-ai-light: #019fa9;
  --ds-product-syntax-ai-button-disabled: var(--ds-gray-600);
  --ds-product-syntax-ace-string-dark: var(--ds-green-200);
  --ds-product-syntax-ace-string-light: var(--ds-blue-500);
  --ds-product-syntax-ace-comment-dark: var(--ds-gray-800);
  --ds-product-syntax-ace-comment-light: var(--ds-green-700);
  --ds-product-syntax-ace-function-dark: #a6e22e;
  --ds-product-syntax-ace-function-light: #3c4c72;

  // Notebook editor
  --ds-product-syntax-ace-string-notebook: var(--ds-blue-500);
  --ds-product-syntax-ace-constant-language-notebook: #585cf6;
  --ds-product-syntax-ace-autocomplete-highlight-notebook: var(--ds-blue-400);
  --ds-product-syntax-ace-autocomplete-linehover-notebook: var(--ds-blue-100);
  --ds-product-syntax-ace-autocomplete-activeline-notebook: var(--ds-gray-400);

  // Platform header
  --ds-platform-header-breadcrumb-text-color: var(--ds-gray-700);
  --ds-platform-header-button-size: 24px;
  --ds-platform-header-button-spacing: 6px;
  --ds-platform-header-mode-studio-link-color: var(--ds-blue-400);
  --ds-platform-header-nav-link-color: var(--ds-gray-700);
  --ds-platform-header-refresh-button-color: var(--ds-green-500);
  --ds-platform-header-refresh-button-hover-color: var(--ds-green-800);

  // Layout
  --grid: 8px;
  --grid-half: calc(var(--grid) * 0.5);
  --grid-quarter: calc(var(--grid) * 0.25);
  --grid-2x: calc(var(--grid) * 2);
  --grid-3x: calc(var(--grid) * 3);
  --grid-4x: calc(var(--grid) * 4);
  --grid-6x: calc(var(--grid) * 6);
  --grid-7x: calc(var(--grid) * 7);

  // Font
  --font-family-header: 'Plain', Helvetica, Arial, sans-serif;
  --font-family-base: 'Graphik', Helvetica, Arial, sans-serif;

  --font-size-default: 12px;
  --font-size-small: 11px;
  --font-size-xsmall: 10px;
  --font-size-med: 14px;
  --font-size-med-large: 16px;
  --font-size-medi-large: 18px;
  --font-size-medi-xlarge: 20px;
  --font-size-large: 22px;
  --font-size-slarge: 24px;
  --font-size-xlarge: 28px;
  --font-size-xxlarge: 36px;
  --font-size-header: var(--font-size-large);

  --font-weight-xlight: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  --line-height-default: 16px;
  --line-height-xsmall: calc(var(--line-height-default) * 0.75);
  --line-height-small: calc(var(--line-height-default) * 0.8125);
  --line-height-medium: calc(var(--line-height-default) * 1.25);
  --line-height-large: calc(var(--line-height-default) * 1.65);
  --line-height-xlarge: calc(var(--line-height-default) * 2.5);
  --line-height-xxlarge: calc(var(--line-height-default) * 3);

  --letter-spacing-default: 0;
  --letter-spacing-medium: calc(var(--letter-spacing-default) + 1);

  // Border radius
  --border-radius-2: 2px;
  --border-radius-4: 4px;
  --border-radius-6: 6px;
  --border-radius-8: 8px;
  --border-radius-10: 10px;

  // Animation
  --transition-duration-200: 200ms;

  // Application values
  --ds-drop-zone-highlight-color: var(--ds-purple-400);
  --ds-input-border-radius: 10px;
  --ds-platform-header-background: var(--ds-gray-1200);

  // gap
  --gap-small: 4px;
  --gap-medium: 8px;

  // Overrides (LEGACY)
  --ds-white-to-gray-1200: var(--ds-gray-1200);
  --ds-black-to-gray-900: var(--ds-gray-900);
  --ds-blue-300-to-blue-500: var(--ds-blue-500);
  --ds-blue-600-to-blue-400: var(--ds-blue-400);
  --ds-blue-600-to-blue-300: var(--ds-blue-300);
  --ds-blue-600-to-blue-100: var(--ds-blue-100);
  --ds-blue-500-to-gray-300: var(--ds-gray-300);
  --ds-blue-500-to-gray-900: var(--ds-gray-900);
  --ds-blue-100-to-gray-300: var(--ds-gray-300);
  --ds-gray-300-to-gray-500: var(--ds-gray-500);
  --ds-gray-300-to-gray-400: var(--ds-gray-400);
  --ds-green-700-to-gray-1200: var(--ds-gray-1200);
  --ds-green-700-to-gray-1100: var(--ds-gray-1100);
  --ds-green-700-to-gray-800: var(--ds-gray-800);
  --ds-green-600-to-green-500: var(--ds-green-500);
  --ds-green-600-to-green-300: var(--ds-green-300);
  --ds-green-600-to-gray-1200: var(--ds-gray-1200);
  --ds-green-600-to-gray-800: var(--ds-gray-800);
  --ds-green-500-to-green-200: var(--ds-green-200);
  --ds-green-500-to-green-300: var(--ds-green-300);
  --ds-gray-400-to-gray-1200: var(--ds-gray-1200);
  --ds-gray-800-to-gray-1200: var(--ds-gray-1200);
  --ds-gray-1000-to-ds-white: var(--ds-white);
  --ds-green-500-to-blue-400: var(--ds-blue-400);
  --ds-green-500-to-gray-1100: var(--ds-gray-1100);
  --ds-green-500-to-gray-500: var(--ds-gray-500);
  --ds-green-200-to-gray-500: var(--ds-gray-500);
  --ds-white-to-green-900: var(--ds-green-900);
  --ds-white-to-gray-1100: var(--ds-gray-1100);
  --ds-border-radius-0-to-border-radius-10: 10px;
  --ds-border-radius-0-to-border-radius-8: 8px;
  --ds-border-radius-2-to-border-radius-4: 4px;
  --ds-border-radius-2-to-border-radius-6: 6px;
  --ds-border-radius-2-to-border-radius-8: 8px;
  --ds-border-radius-3-to-border-radius-10: 10px;
  --ds-border-radius-3-to-border-radius-6: 6px;
  --ds-border-radius-3-to-border-radius-8: 8px;
  --ds-border-radius-4-to-border-radius-10: 10px;
  --ds-border-radius-4-to-border-radius-8: 8px;
  --ds-border-radius-5-to-border-radius-10: 10px;
  --ds-border-radius-8-to-border-radius-14: 8px;
  --ds-border-radius-10-to-border-radius-50: 10px;
  --ds-border-radius-50-to-border-radius-8: 8px;
  --ds-border-radius-0-3-3-0-to-border-radius-0-10-10-0: 0 10px 10px 0;
  --ds-border-radius-3-0-0-3-to-border-radius-10-0-0-10: 10px 0 0 10px;
  --ds-font-weight-200-to-font-weight-400: 400;

  // Shared component variables
  --toggle-switch-background: var(--ds-green-500);

  // Capra button variables
  --capra-button-color: var(--ds-green-800);
  --capra-button-icon-color: var(--ds-green-800);
  --capra-button-disabled-color: var(--ds-gray-700);
  --capra-button-disabled-opacity: 1;
  --capra-button-border-radius: var(--border-radius-10);
  --capra-button-border-radius-small: var(--border-radius-8);
  --capra-button-font-family: 'Graphik', Helvetica, Arial, sans-serif;

  // Primary variables
  --capra-button-primary-background: var(--ds-green-200);
  --capra-button-primary-hover-background: var(--ds-green-400);
  --capra-button-primary-active-background: var(--ds-green-400);
  --capra-button-primary-disabled-background: var(--ds-gray-400);

  // Secondary variables
  --capra-button-secondary-color: var(--ds-gray-1200);
  --capra-button-secondary-icon-color: var(--ds-gray-1200);
  --capra-button-secondary-disabled-color: var(--ds-gray-400);
  --capra-button-secondary-background-color: var(--ds-white);
  --capra-button-secondary-border-color: var(--ds-green-500);
  --capra-button-secondary-hover-background-color: var(--ds-white);
  --capra-button-secondary-hover-border-color: var(--ds-green-600);
  --capra-button-secondary-hover-color: var(--ds-gray-1200);
  --capra-button-secondary-hover-icon-color: var(--ds-gray-1200);
  --capra-button-secondary-active-color: var(--capra-button-secondary-hover-color);
  --capra-button-secondary-active-border-color: var(--ds-green-700);
  --capra-button-secondary-active-background-color: var(--ds-white);
  --capra-button-secondary-disabled-icon-color: var(--ds-gray-400);
  --capra-button-secondary-disabled-border-color: var(--ds-gray-400);

  // Secondary neutral variant
  --capra-button-neutral-color: var(--ds-gray-1200);
  --capra-button-neutral-icon-color: var(--ds-gray-900);
  --capra-button-neutral-border-color: var(--ds-gray-500);
  --capra-button-neutral-hover-icon-color: var(--ds-gray-900);
  --capra-button-neutral-hover-border-color: var(--ds-gray-800);
  --capra-button-neutral-active-border-color: var(--ds-gray-800);

  // Secondary neutral green variant
  --capra-button-neutral-green-color: var(--ds-gray-1200);
  --capra-button-neutral-green-icon-color: var(--ds-green-500);
  --capra-button-neutral-green-border-color: var(--ds-gray-500);
  --capra-button-neutral-green-hover-icon-color: var(--ds-green-500);
  --capra-button-neutral-green-hover-border-color: var(--ds-gray-800);
  --capra-button-neutral-green-active-border-color: var(--ds-gray-800);
}
